.header{
    background-color: rgba(201, 201, 201, 0.9);
}

.header-head{
    width: 90%;
    max-width: 1200px;
    font-size: large;
    font-weight: bold;
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
}

.searchbar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    margin: auto;
}

.search-result{
    width: 80%;
    height: 600px;
    position: absolute;
    overflow: scroll;
    padding: 2px;
    background-color: rgb(236, 238, 238);
    z-index: 2;
}

.search-div{
    width: 96%;
    height: 30px;
    line-height: 26px;
    background-color: white;
    position: relative;
    z-index: 2;
    margin-bottom: 20px;
    display: inline;
}

.search-close{
    margin-left: 10px;
    display: inline;
    color:brown;
    padding: 2px;
    cursor: pointer;
}

.search-input{
    width: 95%;
    padding-left: 15px;
    height: 30px;
    border: none;
}

.search-overlay-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.search-picture{
    width: 100px;
    height: 60px;
    display: inline-block;
}

.search-title{
    display: inline-block;
    padding-left: 20px;
    font-size: 1rem;
}

.search-li{
    display: flex;
    cursor: pointer;
}

.search-li:hover{
    background-color: aliceblue;
}

*:focus {
    outline: none;
}



@media only screen and (max-width: 650px) {
    .searchbar{
      width: 90%;
    }
}