.row {
    width: 100%;
    display: flex;
    background-color: black;
    justify-content: space-evenly;
    color: white;
    font-size: 1rem;
    align-items: center;
  }

  .low-text{
    font-size: 0.75rem;
  }

  .para1{
    cursor: pointer;
    text-align: center;
  }
  
  @media only screen and (max-width: 650px) {
    .row{
      font-size: 0.75rem;
    }
    .low-text{
      font-size: 0.5rem;
    }
  }


  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }
  
  .popup-inner {
    background-color: white;
    padding: 20px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    text-align: justify;
    color:black;
  }
  
  .popup-inner h1 {
    font-size: 24px;
    margin-bottom: 10px;
    color: black
  }
  
  .popup-inner p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    color: black;
  }
  
  .popup-inner button {
    display: block;
    margin: 0 auto;
  }