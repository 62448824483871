.province{
  fill:#a2f37c;
  stroke:rgba(255, 0, 102, 0.662); 
  stroke-width:1; 
  stroke-miterlimit:10;
  fill-opacity: 0;
}

.st7{font-family:'Fredoka one', cursive;}
.st8{font-size:36px;}
.st9{fill:none;stroke:#000000;stroke-miterlimit:10;}
.st10{fill:none;stroke:#000000;stroke-width:3;stroke-miterlimit:10;}
#home-image{
    width: 100%;
    animation: 0.3s fadeIn;
    max-width: 700px;
}

  @media only screen and (max-width: 550px) {
    .st8{
      font-size: 45px;
    }
  }


.cls-1{
  fill:#6c814c;
}
.cls-2{
  fill:#9fc07a;
}
.cls-3{
  fill:#778751;
}
.cls-4{
  fill:#688148;
}
.cls-5{
  fill:#0f2605;
}
.cls-6{
  fill:#658143;
}
.cls-7{
  fill:#1a2b0b;
}
.cls-8{
  fill:#5a7336;
}
.cls-9{
  fill:#4e7431;
}
.cls-10{
  fill:#466632;
}
.cls-12{
  fill:#adff9b;stroke:#000;stroke-miterlimit:10;
}
.cls-13{
  fill:#5d7f3d;
}