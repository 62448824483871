.popular-locations-card {
  width: 250px;
  height: 300px;
  margin-right: 20px;
  transition: all 0.5s;
  cursor: pointer;;
}

.popular-locations-card img:hover {
  opacity: 0.8;
}

.popular-locations-card img {
  width: 250px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 250px;
  object-fit: cover;
  opacity: 1;
}

.popular-locations-card h3 {
  text-align: center;
}

.item-slider-container {
  margin-bottom: 20px;
}


.item-slider {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
  font-family: Fredoka, sans-serif;
  flex-direction: row-reverse;
}

.item-slider .item-container {
  display: inline-flex;
  justify-content: flex-start;
  overflow-x: scroll;
  scroll-behavior: smooth;
  height: 320px;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
}

.item-title{
  font-family: Fredoka, sans-serif;
}

.item-slider .item-container::-webkit-scrollbar {
  display: none;
}

.item-slider .left-arrow-left {
  position: absolute;
  cursor: pointer;
  left: -22px;
  top: 103px;
  z-index: 1;
}

.item-slider .right-arrow-right {
  position: absolute;
  right: -22px;
  top: 110px;
  cursor: pointer;
  z-index: 1;
}

.right-arrow-right>svg,
.left-arrow-left>svg {
  color: #ffffff;
  stroke: black;
  stroke-width: 0.5px;
}

.right-arrow-right svg:hover,
.left-arrow-left svg:hover {
  color: rgb(0, 0, 0);
  border-radius: 50%;
}

.is-disabled-hide{
  display: none;
}