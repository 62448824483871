.about-us {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .about-us-heading {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .about-us-intro {
    font-size: 16px;
    color: #555;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .about-us-mission {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .about-us-mission-text {
    font-size: 14px;
    color: #666;
  }
  
  .mission-list {
    list-style: disc;
    padding-left: 20px;
    margin-top: 10px;
  }
  
  .mission-list li {
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .about-us-thank-you {
    font-size: 16px;
    color: #444;
    text-align: center;
    margin-top: 20px;
  }  

  .mission-list strong {
    color: #007BFF;
    font-weight: bold;
  }