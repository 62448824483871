.st0{
    stroke:black;
    stroke-miterlimit:10;
    fill: #C3423F;
    transition: 0.2s ease-in-out;
    cursor: pointer;
}

.str{
    stroke:black;
    stroke-miterlimit:100;
    fill: rgb(219, 252, 216);
    transition: 0.2s ease-in-out;
    cursor: pointer;
}

#test{
    fill: black;
}

.logo-black-mid{
    fill: #ffffff
}

.logo-white{
    fill: #ffffff
}

#gb-image .st0{
    stroke-width:3;
}

#kp-image .st0{
    stroke-width:1.5;
}

#pjb-image .st0{
    stroke-width:1.5;
}
#bln-image .st0{
    stroke-width:1.5;
}

#snd-image .st0{
    stroke-width:1.5;
}

#ajk-image .st0{
    stroke-width:0.6;
}

/* .st0:hover{
    fill:rgb(147, 179, 139);
}

.str:hover{
    fill:rgb(147, 179, 139);
} */

/* .st1{
    font-family: cursive;
}
.st2{
    font-size:30px;
    user-select: none;
} */

#gb-image{
    max-width: 700px;
    animation: 0.5s fadeIn;
    width: 100%;
  }

#kp-image{
    max-width: 500px;
    animation: 0.5s fadeIn;
    width: 100%;
}
#pjb-image{
    max-width: 550px;
    animation: 0.5s fadeIn;
    width: 100%;
}

#bln-image{
    max-width: 720px;
    animation: 0.5s fadeIn;
    width: 100%;
}

#snd-image{
    max-width: 550px;
    animation: 0.5s fadeIn;
    width: 100%;
}
#ajk-image{
    max-width: 350px;
    animation: 0.5s fadeIn;
    width: 100%;
}


  @keyframes fadeIn {
    from { 
        opacity: 0;
    }
    to { 
        opacity: 1;
     }
  }

  #map-back{
      padding: 0;
      border: 0;
      color: black;
      transition: 0.2s ease-in-out;
  }

  #map-back:hover{
    color:rgb(147, 179, 139);
  }

  @media only screen and (max-width: 850px) {
    #gb-image{
        max-width: 575px;
    }
  }

  @media only screen and (max-width: 650px) {
    #gb-image{
        margin: 0 auto 0;
    }
    #kp-image{
        margin: 0 auto 0;
    }
    #pjb-image{
        margin: 0 auto 0;
    }
    #bln-image{
        margin: 0 auto 0;
    }
    #snd-image{
        margin: 0 auto 0;
    }
    #ajk-image{
        margin: 0 auto 0;
    }
  }

  