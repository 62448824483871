.district-cards-place{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.card {
    position: relative;
    overflow: hidden;
    /* font-family: 'Fredoka One', cursive; */
    height: 230px;
    width: 31%;
    /* display: inline-block; */
    /* border-radius: 5px; */
    transition: all 0.5s;
    /* margin: 10px 2% 10px 0; */
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    margin: 10px;
    box-shadow: 0 0 0 transparent;
    border-radius: 5px;
  }

  .card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .card:hover {
    transform: translateY(-2px) scale(1.005);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }

  .card:hover .text{
    visibility: visible;
  }

  /* .card:hover::before {
    border-color: rgba(163, 163, 163, 0.563); /* Adjust the border color as needed 
  }*/
  
  .text {
    width: 100%;
    height: 230px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    align-items: center;
    top: 0;
    margin: 0;
    visibility: hidden;
    text-transform: capitalize;
  }

  .img-card{
      width: 100%; 
      height: 100%; 
      border-radius: 5px;
}

@media only screen and (max-width: 850px) {
  .card{
    width: 49%;
    height: 30vw;
    margin: 10px 1% 10px 0;
  }

  .card:hover {
    transform: none;
    border: none;
  }

  .text{
    height: 30vw;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 500px) {
  .card{
    width: 100%;
    height: 60vw;
    margin: 10px 0 10px 0;
  }
  .card:hover {
    transform: none;
    border: none;
  }
  .text{
    height: 60vw;
    font-size: 1.5rem;
  }
}