.province-text{
    width: 50%;
    text-transform: capitalize;
    font-size: 3rem;
    font-weight: 600;
}

.district-info-place{
  width: 100%;
  display: flex;
}

.province-text-container{
    width: 50%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 10px;
    z-index: -1;
}

.district-info-container{
    font-family: Fredoka, sans-serif;
    height: 60px;
    padding-top: 2px;
    margin: auto;   
}

.district-name{
    font-size: 1.2rem;
    color: black;
    text-transform: uppercase;
    font-weight: 600;
}

.district-select{
  font-size: 1.2rem;
  text-transform: uppercase;
  max-height: 250px;
  overflow-y: auto;
}

.bottom-province-text-container{
    width: 100%;
    text-align: center;
  }

  .color-legend-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .color-legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .color-square {
    width: 10px;
    height: 10px;
  }
  
  .legend-text {
    margin-left: 10px;
    font-size: 0.75rem;
  }
  
  .red {
    background-color: #C3423F;
  }
  
  .blue {
    background-color: rgb(219, 252, 216);
  }

@media only screen and (max-width: 750px) {
    .province-text{
        font-size: 2rem;
    }
    .district-info-container{
        height: 60px;
    }
    .legend-text{
      font-size: 0.6rem;
    }
  }

  @media only screen and (max-width: 650px) {
    .province-text{
      margin: auto;
    }
    .color-legend-item{
      margin: auto;
    }
  }

  @media only screen and (max-width: 450px) {
    .district-name{
        font-size: 10px;
    }
    .province-text{
        font-size: 1.5rem;
    }
  }

  
  