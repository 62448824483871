body{
  transition: background-color 0.5s;
  background-color:white;
}

.adsterra-native-banner-footer-cover{
  max-width: 1000px;
  margin: auto;
}

.main-container{
  min-height: 95vh;
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
  width: 90%;
}

.province-info-place{
  width: 100%;
  position: absolute;
  top: -40px;
  z-index: -1;
}

.map-place{
  flex-grow: 1;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
}

.hero-text{
  text-transform: capitalize;
  font-size: 4rem;
  font-weight: 600;
}

.side-text-container{
  width: 50%;
  position: absolute;
  top: 135px
}

.bottom-text-container{
  width: 100%;
  text-align: center;
}

.info-container{
  font-family: Fredoka, sans-serif;
  width: 30%;
  height: 100px;
  padding-top: 2px;
  margin-top: 50px;
  margin-left: 10px;
}

.info-name{
  font-size: 15px;
  margin: 0;
  color: black;
  text-transform: uppercase;
  font-weight: 600;
}
.info-desc{
  font-size: 15px;
  color: black;
  margin: 0;
}

.map-prompt{
  font-size: 15px;
  color: black;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0 10px 0;
}

.button {
  width: 100px;
  background-color: black;
  border: none;
  border-radius: 5px;
  color: white;
  text-align: center;
  padding: 10px 10px;
  text-decoration: none;
  font-size: 1em;
  text-transform: uppercase;
  margin: 0px auto 10px; 
  display: block;
  cursor: pointer;
}

@media only screen and (max-width: 1050px) {
  .hero-text{
    font-size: 3rem;
  }
}

@media only screen and (max-width: 900px) {
  .hero-text{
    font-size: 2rem;
  }
}
@media only screen and (max-width: 650px) {
  .hero-text{
    width: 80%;
    text-align: center;
    margin: auto;
  }

  .text-container{
    margin: auto;
  }

  .info-name{
    font-size: 12px;
  }
  .info-desc{
    font-size: 12px;
  }
}

.login-page {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
}
.login-form {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.login-form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.login-form button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #4CAF50;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}
.login-form button:hover,.form button:active,.form button:focus {
  background: #43A047;
}

.password-failure-message{
  color: red;
}

.header-button {
  display: inline-block;
  font-size: 0.9rem;
  padding: 3px 8px;
  margin-right: 10px;
  border-radius: 10px;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.header-button:hover {
  background-color: #2980b9;
  cursor: pointer;
}
